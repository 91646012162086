<template>
	<div>
		<br>
		<br>
		<br>
		<h1>啥都没现在</h1>
	</div>
</template>

<script>
export default {
	name: 'About',
	/* beforeDestroy() {
		console.log('About组件即将被销毁了')
	},*/
	/* mounted() {
		console.log('About组件挂载完毕了',this)
		window.aboutRoute = this.$route
		window.aboutRouter = this.$router
	},  */
}
</script>